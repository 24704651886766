<template>
    <div>
        <simpleDialog :show="dialogVisible">
            <div class="cert">
                <p class="title">店铺认证提醒</p>
                <p>店铺认证提醒 尊敬的商家，您好：</p>
                <p class="lf2">为了保障交易双方的权益和安全，入驻平台需提供相关资质证明。未认证可能导致支付风控等安全问题，请尽快认证。</p>
                <p class="down">提交入口见下图↓ </p>
                <img class="img" src="~@ass/img/1.4.50/certToCtf.png" alt="" />
                <div class="flex-right mt-26">
                    <el-button type="primary" @click="next">
                        去认证
                    </el-button>
                </div>
            </div>
        </simpleDialog>
    </div>
</template>

<script>
import API from '@/request/api'
import simpleDialog from '@cm/base/simpleDialog'
export default {
    data() {
        return {
            dialogVisible: false,
        };
    },
    components:{
        simpleDialog,
    },
    created() {
        this.getStatus()
    },
    methods: {
        getStatus(){
            this.$http({
                name: API.certGetStatus_Api.name,
                url: API.certGetStatus_Api.url,
                data: {},
                callback:(res) => {
                    if(!res.data) this.dialogVisible = true
                },
            })
        },
        handleClose(){
            this.dialogVisible = false
        },
        next(){
            // 跳转开始认证页面
            this.$router.push({
                path:'/setting/school/storeCertification',
                query:{
                    certType:'start',
                    type:1,
                    entity_type:0,
                },
            })
        }
    }
};
</script>

<style scoped lang="scss">
.cert {
    p {
        font-size: 14px;
        margin-bottom: 10px;
    }
    .title {
        font-size: 16px;
        font-weight: bold;
        color: rgba(51, 51, 51, 1);
        margin-bottom: 20px;
    }
    .lf2 {
        text-indent: 2em;
    }
    .down {
        margin-bottom: 0;
    }
    .img {
        width: 100%;
    }
}
::v-deep .el-dialog {
    margin: 0 !important;
    height: 560px !important;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
}
::v-deep .el-dialog__header {
    border-bottom: none !important;
}
</style>