<template>
    <div>
        <el-dialog title="" :visible.sync="dialogVisible" width="800px" :before-close="handleClose">
            <slot></slot>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props:{
        show:Boolean,
    },
    watch:{
        show:{
            handler(newObj){
                this.dialogVisible = newObj
            },
            immediate:true,
        }
    },
    data() {
        return {
            dialogVisible: false,
        };
    },
    methods: {
        handleClose(){
            this.dialogVisible = false
        },
    }
};
</script>

<style scoped lang="scss">
</style>