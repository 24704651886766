<!--
 * @Descripttion: teacher.dingdingkaike
 * @version: 1.1pcMenu
 * @Author: sueRimn
 * @Date: 2019-11-05 17:21:49
 * @LastEditors: cyy
 * @LastEditTime: 2022-08-19 15:02:17
 -->
<template>
  <div class="school-overview">
    <div class="renew" v-if="userInfo.version_id == 1 || overdue == -1">
      <span class="renew_explain">
        您的网校已降为免费版，为保证网校功能的正常使用，请尽快升级版本
      </span>
      <el-button @click="setIframeSrc('/Wallet/VIPIntroduction')">
        立即升级
      </el-button>
    </div>
    <div class="school-wrap">
      <div class="school-info-wrap">
        <div class="main-info">
          <div class="cp lf" @click="$router.push({ path: '/setting/school' })">
            <!-- <div class="letter" v-if="jgInfo.title === '叮叮开课网校'">叮</div> -->
            <div
              class="letter has-title"
              :style="`background-image:url(${jgInfo.jg_logo});`"
            ></div>
            <div class="detail">
              <h3>
                {{ jgInfo.title }}
                <i
                  class="version-icon"
                  :class="{
                    'bz-version': Number(userInfo.version_id) === 2,
                    'zy-version': Number(userInfo.version_id) === 3,
                    'zx-version': Number(userInfo.version_id) === 4,
                  }"
                ></i>
                <span
                  v-if="
                    !(jgInfo.jg_logo && jgInfo.title && jgInfo.jg_introduce)
                  "
                >
                  完善网校信息
                </span>
              </h3>
              <p>网校链接：{{ jgInfo.domain }}</p>
            </div>
          </div>
          <div class="rt">
            <span>
              有效至：{{
                userInfo.remainingTime[1] | formatTimeStamp('yyyy-MM-dd')
              }}
            </span>
            <button
              class="cp pay-btn"
              @click="setIframeSrc('/Wallet/VIPIntroduction')"
            >
              立即续费
            </button>
          </div>
        </div>
        <!-- <div class="cp broadcast" @click="getMsg">
        <span class="content">
           <i></i>叮叮开课每周更新内容预告 
        </span>
        <span class="time">
          2019-10-15
          <span>更多</span>
        </span>
      </div> -->
      </div>
      <div class="functional-area">
        <div class="area_left">
          <!--数据概览-->
          <div class="model-block">
            <div class="first">
              <div class="title flex-center">
                经营概况
                <helpIcon
                  style="margin-left: 10px"
                  content="收入：指支付成功的订单收入，包括已作废、已过期的订单，以及打赏送礼的收入<br/>
                            访问：指登录网校和访问课程的学员人数，去重复(重复访问算1人)<br/>
                            叮豆：由叮叮开课推出，用于使用部分网校功能时抵扣现金消耗的形式，兑换比例为1人民币=10个叮豆<br/>
                            今日数据为实时更新，本月和全部数据次日凌晨更新"
                ></helpIcon>
              </div>
            </div>
            <div class="content">
              <div class="a-item" @click="toAssetAdmin">
                <h4>{{ jgInfo.today_total_income | priceSplit }}</h4>
                <p>今日收入(元)</p>
              </div>
              <div class="a-item" @click="toAssetAdmin">
                <h4>{{ jgInfo.the_month_total_income | priceSplit }}</h4>
                <p>本月收入(元)</p>
              </div>
              <div class="a-item" @click="toAssetAdmin">
                <h4>{{ jgInfo.earnings }}</h4>
                <p>累计总收入(元)</p>
              </div>
            </div>
            <div class="content">
              <div class="a-item" @click="toRecords()">
                <h4>{{ jgInfo.today_user }}</h4>
                <p>今日访问(人)</p>
              </div>
              <div class="a-item" @click="toRecords()">
                <h4>{{ jgInfo.the_month_user | priceSplit }}</h4>
                <p>本月访问(人)</p>
              </div>
              <div class="a-item" @click="toAssetAdmin">
                <h4>{{ userInfo.t_amount | priceSplit }}</h4>
                <p v-if="userInfo.t_amount <= 1000" class="aa">叮豆较少</p>
                <p v-else>叮豆余额(个)</p>
                <!-- <i><i v-if="userInfo.t_amount <= 1000" class="el-icon-info"></i></p> -->
              </div>
            </div>
            <div class="tip" v-if="userInfo.t_amount <= 1000">
              <div class="zi1">
                叮豆剩余量较少，建议及时充值，以免影响讲师授课、学员学习及采集学员手机号功能使用
              </div>
              <div
                class="bt"
                @click="$router.push('/capitalAccount/TopUpDingDou')"
              >
                立即充值
              </div>
            </div>
          </div>

          <div class="model-block">
            <h3 class="title">第三方快捷入口</h3>
            <div class="content">
              <div
                class="grid-content bg-purple"
                @click="$store.dispatch('open', 'https://mp.weixin.qq.com/')"
              >
                <img src="~@ass/img/1.4.4.9/icon_wxgzh.svg" alt="" />
                <div class="name">微信公众号</div>
              </div>
              <div
                class="grid-content bg-purple-light"
                @click="$store.dispatch('open', 'https://pay.weixin.qq.com/')"
              >
                <img src="~@ass/img/1.4.4.9/icon_wxzf .svg" alt="" />
                <div class="name">微信支付</div>
              </div>
              <div
                class="grid-content bg-purple"
                @click="$store.dispatch('open', 'https://b.alipay.com/')"
              >
                <img
                  style="height:20px"
                  src="~@ass/img/1.4.4.9/支付平台-支付宝.svg"
                  alt=""
                />
                <div class="name">支付宝商家中心</div>
              </div>
              <div
                class="grid-content bg-purple"
                @click="$store.dispatch('open', 'https://ai.38k.cn/')"
              >
                <img src="~@ass/img/1.4.4.9/icon_fire_cat.png" alt="" />
                <div class="name">火眼猫AI</div>
              </div>
              <!-- <div
                class="grid-content bg-purple"
                @click="$store.dispatch('open', 'https://www.iconfont.cn/')"
              >
                <img src="~@ass/img/1.4.4.9/icon_albbtb.png" alt="" />
                <div class="name">阿里巴巴图标</div>
              </div> -->
              <div
                class="grid-content bg-purple-light"
                @click="
                  $store.dispatch(
                    'open',
                    'https://www.chuangkit.com/designtools/designindex'
                  )
                "
              >
                <img src="~@ass/img/1.4.4.9/icon_ckt.svg" alt="" />
                <div class="name">创客贴</div>
              </div>
              <div
                class="grid-content bg-purple"
                @click="$store.dispatch('open', 'https://ad.oceanengine.com/')"
              >
                <img src="~@ass/img/1.4.4.9/icon_jlyq.svg" alt="" />
                <div class="name">巨量引擎</div>
              </div>
            </div>
          </div>

          <div class="model-block">
            <h3 class="title">常用功能</h3>
            <div class="content">
              <div
                class="cp a-item3"
                @click="$router.push({ path: '/courseClassification' })"
              >
                <div class="ico-wrap">
                  <img src="../../assets/img/1.3.5/icon_kfcl.png" alt="" />
                  <span>课程分类</span>
                </div>
              </div>
              <div
                class="cp a-item3"
                @click="
                  $router.push({ path: '/manageStudent', query: { type: 3 } })
                "
              >
                <div class="ico-wrap">
                  <img src="../../assets/img/1.3.5/icon_xyfz.png" alt="" />
                  <span>学员分组</span>
                </div>
              </div>
              <div
                class="cp a-item3"
                @click="
                  $router.push({ path: '/manageStudent', query: { type: 2 } })
                "
              >
                <div class="ico-wrap">
                  <img src="../../assets/img/1.3.5/icon_xybq.png" alt="" />
                  <span>学员标签</span>
                </div>
              </div>
              <div
                class="cp a-item3"
                @click="$router.push({ path: '/setting/school' })"
              >
                <div class="ico-wrap">
                  <img src="../../assets/img/1.3.5/icon_wxsz.png" alt="" />
                  <span>网校设置</span>
                </div>
              </div>
            </div>

            <div class="content">
              <div
                class="cp a-item3"
                @click="$router.push({ path: '/capitalAccount/TopUpDingDou' })"
              >
                <div class="ico-wrap">
                  <img src="../../assets/img/1.3.5/icon_ddcz.png" alt="" />
                  <span>叮豆充值</span>
                </div>
              </div>
              <div
                class="cp a-item3"
                @click="
                  $router.push({ path: '/manageStudent', query: { type: 1 } })
                "
              >
                <div class="ico-wrap">
                  <img src="../../assets/img/1.3.5/icon_hmd.png" alt="" />
                  <span>黑名单</span>
                </div>
              </div>
              <div
                class="cp a-item3"
                @click="$router.push({ path: '/capitalOrder' })"
              >
                <div class="ico-wrap">
                  <img src="../../assets/img/1.3.5/icon_ddgl.png" alt="" />
                  <span>订单管理</span>
                </div>
              </div>
              <div
                class="cp a-item3"
                @click="$router.push({ path: '/setting/school' })"
              >
                <div class="ico-wrap">
                  <img src="../../assets/img/1.3.5/icon_mgc.png" alt="" />
                  <span>敏感词</span>
                </div>
              </div>
            </div>

            <div class="content3">
              <div
                class="cp a-item6"
                @click="$router.push({ path: '/setting/collect' })"
              >
                <div class="ico-wrap">
                  <img src="../../assets/img/1.3.5/icon_cjsjh.png" alt="" />
                  <span>账号绑定</span>
                </div>
              </div>
              <div
                class="cp a-item6"
                @click="$router.push({ path: '/setting/wechat' })"
              >
                <div class="ico-wrap">
                  <img src="../../assets/img/1.3.5/icon_glgzh.png" alt="" />
                  <span>关联公众号</span>
                </div>
              </div>
            </div>
          </div>

          <div class="model-block">
            <h3 class="title">知识产品</h3>

            <div class="content">
              <div
                class="cp a-item3"
                @click="$router.push({ path: '/courseLive' })"
              >
                <div class="ico-wrap">
                  <img
                    src="../../assets/img/1.3.5/icon_zbk.png"
                    width="20"
                    height="14"
                    class="img2"
                    alt=""
                  />
                  <span>直播课</span>
                </div>
              </div>
              <div
                class="cp a-item3"
                @click="$router.push({ path: '/courseRecord' })"
              >
                <div class="ico-wrap">
                  <img src="../../assets/img/1.3.5/icon_lbk.png" alt="" />
                  <span>录播课</span>
                </div>
              </div>
              <div
                class="cp a-item3"
                @click="$router.push({ path: '/coursePrivate' })"
              >
                <div class="ico-wrap">
                  <img src="../../assets/img/1.3.5/icon_xbk.png" alt="" />
                  <span>小班课</span>
                </div>
              </div>
              <div
                class="cp a-item3"
                @click="$router.push({ path: '/library/video' })"
              >
                <div class="ico-wrap">
                  <img src="../../assets/img/1.3.5/icon_ysp.png" alt="" />
                  <span>音视频</span>
                </div>
              </div>
            </div>

            <div class="content">
              <div
                class="cp a-item3"
                @click="$router.push({ path: '/courseLive' })"
              >
                <div class="ico-wrap">
                  <img src="../../assets/img/1.3.5/icon_jszb.png" alt="" />
                  <span>极速直播</span>
                </div>
              </div>
              <div
                class="cp a-item3"
                @click="$router.push({ path: '/library/playback' })"
              >
                <div class="ico-wrap">
                  <img src="../../assets/img/1.3.5/icon_hf.png" alt="" />
                  <span>回放管理</span>
                </div>
              </div>
              <div
                class="cp a-item3"
                @click="$router.push({ path: '/courseSeries' })"
              >
                <div class="ico-wrap">
                  <img src="../../assets/img/1.3.5/icon_xlk.png" alt="" />
                  <span>系列课</span>
                </div>
              </div>
              <div class="cp a-item3" @click="$router.push({ path: '/class' })">
                <div class="ico-wrap">
                  <img src="../../assets/img/1.3.5/icon_kcb.png" alt="" />
                  <span>课程表</span>
                </div>
              </div>
            </div>
          </div>

          <div class="model-block">
            <h3 class="title">营销产品</h3>

            <div class="content">
              <div
                class="cp a-item7 bgcolor1"
                style="background: #fe5b5b"
                @click="
                  isVersionFree
                    ? $root.toggleUpdatePopup()
                    : !showTeamBtn
                    ? forbidden()
                    : setIframeSrc('/TeamCourse/TeamCourseList')
                "
              >
                <div class="ico-wrap">
                  <img src="../../assets/img/1.3.5/icon_pt11.png" alt="" />
                  <span>拼团</span>
                </div>
              </div>
              <div
                class="cp a-item7 bgcolor2"
                @click="
                  isVersionFree
                    ? $root.toggleUpdatePopup()
                    : $router.push({
                        path: '/marketingRedeem',
                        query: { type: 1 },
                      })
                "
              >
                <div class="ico-wrap">
                  <img
                    src="../../assets/img/1.3.5/icon_duihuanm22.png"
                    alt=""
                  />
                  <span>兑换码</span>
                </div>
              </div>
              <div
                class="cp a-item7 bgcolor3"
                @click="
                  isVersionFree
                    ? $root.toggleUpdatePopup()
                    : $router.push({
                        path: '/marketingRedeem',
                        query: { type: 2 },
                      })
                "
              >
                <div class="ico-wrap">
                  <img src="../../assets/img/1.3.5/icon_tqm33.png" alt="" />
                  <span>提取码</span>
                </div>
              </div>
            </div>

            <div class="content" style="padding-top: 20px">
              <div
                class="cp a-item7 bgcolor4"
                @click="
                  isVersionFree
                    ? $root.toggleUpdatePopup()
                    : !showPosterBtn
                    ? forbidden()
                    : setIframeSrc('/marketingFission', 1)
                "
              >
                <div class="ico-wrap">
                  <img src="../../assets/img/1.3.5/icon_lbhb44.png" alt="" />
                  <span>裂变海报</span>
                </div>
              </div>
              <div
                class="cp a-item7 bgcolor5"
                @click="
                  isVersionFree
                    ? $root.toggleUpdatePopup()
                    : !showCouponBtn
                    ? forbidden()
                    : setIframeSrc('/CourseCoupon/couponList')
                "
              >
                <div class="ico-wrap">
                  <img src="../../assets/img/1.3.5/icon_yhq55.png" alt="" />
                  <span>优惠码</span>
                </div>
              </div>
              <div
                class="cp a-item7 bgcolor6"
                @click="
                  isVersionFree
                    ? $root.toggleUpdatePopup()
                    : $router.push({ path: '/tool/naire' })
                "
              >
                <div class="ico-wrap">
                  <img src="../../assets/img/1.3.5/icon_dcb66.png" alt="" />
                  <span>调查表</span>
                </div>
              </div>
            </div>
          </div>

          <div class="model-block" v-if="jgInfo.business.length > 0">
            <div class="title">商家学院</div>
            <div class="content content4">
              <div
                v-for="(item, index) in jgInfo.business"
                :key="index"
                class="cp bg"
                @click="openNewUrl(item.url)"
              >
                <img class="bg" :src="item.src" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="area_right">
          <div v-if="jgInfo.activity.length > 0">
            <div v-if="jgInfo.activity.length > 1">
              <el-carousel
                class="mb20 cp"
                height="194px"
                ref="carousel"
                @click.native="linkTo"
              >
                <el-carousel-item
                  v-for="(item2, index2) in jgInfo.activity"
                  :key="index2"
                >
                  <img width="258" height="194" :src="item2.src" alt="" />
                </el-carousel-item>
              </el-carousel>
            </div>
            <div v-else class="mb20 cp" @click="linkTo">
              <img
                width="258"
                height="194"
                :src="jgInfo.activity[0].src"
                alt=""
              />
            </div>
          </div>
          <div class="product-trends">
            <div class="title">产品动态</div>
            <ul>
              <li
                v-for="(item3, index3) in jgInfo.systemMsg"
                :key="index3"
                :class="{ yanse: acitving == index3 + 1 }"
                @click="producttrends_dialog(index3, item3)"
              >
                <div>{{ item3.title | ellipsis(13) }}</div>
                <div>{{ item3.c_time | formatTimeStamp('yyyy-MM-dd ') }}</div>
              </li>
            </ul>
          </div>
          <div class="speed-channel">
            <div class="title">极速通道</div>
            <ul>
              <li v-for="(item4, index4) in jgInfo.fastTrack" :key="index4">
                <div class="flex-center">
                  <div class="tu">
                    <img :src="item4.img" alt="" />
                  </div>
                  <div class="des">
                    <div class="name">{{ item4.name }}</div>
                    <div class="introduce">{{ item4.test }}</div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 产品动态弹窗 -->
      <el-dialog
        :visible.sync="dialogVisible"
        width="568px"
        @close="dialogVisible_close"
      >
        <div slot="title">
          <div class="dialog_font1">{{ dialogVisible_value.title }}</div>
          <div class="dialog_font2">
            发布日期：
            {{ dialogVisible_value.c_time | formatTimeStamp('yyyy-MM-dd ') }}
          </div>
        </div>
        <div class="contenttt">
          <!-- {{
            dialogVisible_value.c_time | formatTimeStamp('yyyy-MM-dd ')
          }}更新内容 -->
          <div
            class="dialogVisible_container"
            v-html="dialogVisible_value.text"
          ></div>
        </div>
      </el-dialog>
      <!--嵌套-->
      <my-iframe
        v-if="iframeSrc"
        :src="iframeSrc"
        @close="iframeSrc = ''"
      ></my-iframe>
      <!--创建课程弹窗-->
      <createClass
        v-if="createCourseConfirm"
        @close="createCourse"
        @setIframeSrc="setIframeSrc"
        :live_class="userInfo.live_class"
        :dingdou="userInfo.t_amount"
        :private_classes="userInfo.private_classes"
      ></createClass>
    </div>
    <showCert></showCert>
  </div>
</template>
<script>
import bg from '@/mixin/background'
import helpIcon from '@/components/helpIcon'
import { mapState, mapGetters } from 'vuex'
import createClass from '@/components/CreateCourseConfirm'
import showCert from './components/showCert'
export default {
  mixins: [bg],
  data() {
    return {
      acitving: 0,
      dialogVisible: false,
      bb: '1.3.1学员模块优化1员模块优化1员模块优化1',
      iframeSrc: '',
      createCourseConfirm: false,
      dialogVisible_value: [],
      jgInfo: {
        business: '',
        earnings: '',
        jg_logo: '',
        title: '',
        teacher: {},
        activity: [{ url: '', src: '' }],
        today_order_num: 0,
        today_total_income: 0,
        remainingTime: [],
      },
    }
  },
  filters: {
    ellipsis(value, len) {
      if (!value) return ''
      if (value.length > len) {
        return value.slice(0, len) + '...'
      }
      return value
    },
  },
  components: {
    helpIcon,
    createClass,
    showCert,
  },
  computed: {
    ...mapState(['currRouterPage', 'userInfo', 'isWindowsApp', 'customJgInfo']),
    ...mapGetters(['isVersionFree', 'isVersionStandard']),
    overdue() {
      return this.jgInfo.remainingTime[0]
    },

    showTeamBtn() {
      return this.$store.state.roots.includes(40)
    },
    showRedemptionCodeBtn() {
      return this.$store.state.roots.includes(28)
    },
    showExtractionCodeBtn() {
      return this.$store.state.roots.includes(28)
    },
    showPosterBtn() {
      return this.$store.state.roots.includes(38)
    },
    showCouponBtn() {
      return this.$store.state.roots.includes(39)
    },
  },
  created() {
    this.getJiGouInformation()
  },
  methods: {
    dialogVisible_close() {
      this.acitving = 0
    },
    //产品动态弹窗
    producttrends_dialog(val, val2) {
      this.acitving = val + 1
      this.dialogVisible_value = val2
      this.dialogVisible = true
    },
    // 跳转到直播课创建
    toSurvy() {
      this.$router.push({
        path: '/tool/naire',
      })
    },
    // 跳转到直播课创建
    toCreatePage() {
      this.$router.push({
        path: '/courseLive/AddCourse',
        query: {
          courseType: 1,
        },
      })
    },
    // 无权限
    forbidden() {
      this.$router.push({
        name: 'forbidden',
      })
    },
    // 跳转到学员管理
    toUserAdmin() {
      this.$router.push({
        path: '/manageStudent',
      })
    },
    toAssetAdmin() {
      this.$router.push({
        path: '/capitalAccount',
      })
    },
    // 跳转访问记录 1是今天2是本月
    toRecords(type=1){
      let startTime;
      let endTime;

      const today = new Date();

      if (type === 1) { // 今天
        // 设置开始时间为今天0点0分0秒
        startTime = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        // 设置结束时间为今天23点59分59秒
        endTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59);
      } else if (type === 2) { // 本月
        // 开始时间是本月的第一天0点0分0秒
        startTime = new Date(today.getFullYear(), today.getMonth(), 1);
        // 结束时间是本月的最后一天23点59分59秒
        // 获取下个月的第一天，然后减去一天得到本月的最后一天
        endTime = new Date(today.getFullYear(), today.getMonth() + 1, 0, 23, 59, 59);
      }

      // 将时间戳转换为秒级的数字
      startTime = startTime.getTime()/1000;
      endTime = endTime.getTime()/1000;

      this.$router.push({
        path: '/statisticalAnalysis/records',
        query: {
          startTime,
          endTime,
        },
      });
    },
    // 获取机构的一些信息
    getJiGouInformation() {
      this.$http({
        name: 'jigouInformation',
        url: '/User/jigouInformation',
        callback: ({ data }) => {
          if (data.teacher) {
            this.$router.replace({
              path: '/class',
            })
            return
          }
          this.jgInfo = Object.assign({}, this.jgInfo, data)
          this.$bus.$emit('cyy', this.jgInfo)
        },
      })
    },
    setIframeSrc(src, go) {
      if (go == 1) {
        this.$router.push({
          path: src,
        })
        return
      }
      this.iframeSrc = src
    },
    // 点击消息通知
    getMsg() {
      this.$root.toggleProductUpdate()
    },

    // 创建课程
    createCourse() {
      this.createCourseConfirm = !this.createCourseConfirm
    },

    // 商家学院点击跳转新标签页
    openNewUrl(url) {
      this.$store.dispatch('open', url)
    },

    //图像跳转
    linkTo() {
      if (this.jgInfo.activity.length > 1) {
        let activeIndex = this.$refs.carousel.activeIndex
        let url = this.jgInfo.activity[activeIndex].url
        this.$store.dispatch('open', url)
      } else {
        const url2 = this.jgInfo.activity[0].url
        this.$store.dispatch('open', url2)
      }
    },

    // 跳转帮助页面
    locationHelpPage() {
      this.$store.dispatch('open', this.userInfo.helpPageUrl)
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 20px;
}
::v-deep .el-dialog {
  height: 428px;
}
::v-deep .el-dialog__header {
  padding: 14px 20px;
  border-bottom: 1px solid #f5f5f5;
}
.yanse {
  color: #1b9d97;
}
.contenttt {
  font-size: 12px;
  color: #333333;
  line-height: 16px;
  overflow: auto;
  .dialogVisible_container {
    height: 308px;
  }
  ul {
    height: 308px;
    li {
      height: 20px;
      margin-top: 20px;
    }
  }
}
.dialog_font1 {
  font-size: 15px;
  font-weight: bold;
  color: #333333;
  line-height: 15px;
}
.dialog_font2 {
  margin-top: 10px;
  font-size: 12px;
  color: #999999;
  line-height: 12px;
}
::-webkit-scrollbar {
  display: none;
}
.title {
  font-size: 16px;
  font-weight: bold;
  color: rgba(51, 51, 51, 1);
  line-height: 21px;
  display: inline;
}
::v-deep .el-carousel__button {
  height: 8px;
  width: 8px;
  border-radius: 50%;
}
.functional-area {
  display: flex;
  justify-content: space-between;
  .area_left {
    width: calc(100% - 275px);
  }
  .area_right {
    margin-left: 20px;
    width: 258px;
    .el-carousel__item h3 {
      color: #475669;
      font-size: 14px;
      opacity: 0.75;
      line-height: 150px;
      margin: 0;
    }
    .el-carousel__item:nth-child(2n) {
      background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
      background-color: #d3dce6;
    }
    .speed-channel {
      background-color: #fff;
      margin-top: 20px;
      padding: 20px;
      ul {
        li {
          margin-top: 20px;
        }
      }
      .des {
        margin-left: 14px;
        .name {
          margin-bottom: 10px;
          font-size: 15px;
          color: #333333;
          line-height: 20px;
        }
        .introduce {
          font-size: 12px;
          color: #999999;
          line-height: 16px;
        }
      }
      .tu {
        height: 60px;
        width: 60px;
        img {
          height: 60px;
          width: 60px;
        }
      }
    }
    .product-trends {
      box-sizing: border-box;
      padding: 20px;
      overflow: auto;
      width: 258px;
      height: 222px;
      background-color: #fff;
      ul {
        width: 100%;
        font-size: 12px;
        color: #999999;
        line-height: 13px;
        li {
          cursor: pointer;
          height: 12px;
          width: 100%;
          margin-top: 20px;
          display: flex;
          justify-content: space-between;
          div {
            float: left;
          }
          div:first-child {
            color: #666666;
            font-size: 13px;
            margin-right: 2px;
            width: 149px;
          }
          div:first-child:hover {
            color: #1b9d97;
          }
        }
        li:hover {
          color: #1b9d97;
        }
      }
    }
  }
}
.renew {
  width: 100%;
  height: 60px;
  background: #f14c4c;
  display: flex;
  align-items: center;
  .renew_explain {
    margin-right: 80px;
    text-indent: 20px;
    height: 17px;
    font-size: 17px;
    font-weight: 500;
    color: #ffffff;
    line-height: 17px;
  }
  .el-button {
    font-size: 16px;
    color: #ff3535;
  }
}
.school-wrap {
  position: relative;
  min-width: 1000px;
  padding: 20px;
  background: #f5f5f5;
  box-sizing: border-box;
  .school-info-wrap {
    width: 100%;
    margin-bottom: 20px;
    background: #fff;
    .main-info {
      -webkit-display: flex;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      border-bottom: 1px solid #f5f5f5;
      .lf {
        display: flex;
        align-items: center;
        .letter {
          width: 40px;
          height: 40px;
          margin-right: 16px;
          background-color: rgba(27, 157, 151, 1);
          border-radius: 2px;
          font-size: 20px;
          font-weight: bold;
          color: rgba(255, 255, 255, 1);
          line-height: 40px;
          text-align: center;
        }
        .has-title {
          background-color: transparent;
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }
        .detail {
          h3 {
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: bold;
            color: rgba(51, 51, 51, 1);
            line-height: 21px;
            span {
              font-size: 12px;
              color: rgba(10, 162, 155, 1);
              line-height: 16px;
            }
          }
          .version-icon {
            display: inline-block;
            width: 42px;
            height: 18px;
            margin: 0 19px 0 3px;
            background: url('../../assets/img/schoolInfo/ico_mfbbs@2x.png')
              no-repeat center/100% 100%;
          }
          .bz-version {
            background: url('../../assets/img/schoolInfo/ico_bzbbs@2x.png')
              no-repeat center/100% 100%;
          }
          .zy-version {
            background: url('../../assets/img/schoolInfo/ico_zybbs@2x.png')
              no-repeat center/100% 100%;
          }
          .zx-version {
            background: url('../../assets/img/schoolInfo/ico_zxbbs@2x.png')
              no-repeat center/100% 100%;
          }
          p {
            font-size: 12px;

            color: rgba(128, 128, 128, 1);
            line-height: 16px;
          }
        }
      }
      .rt {
        display: flex;
        align-items: center;
        span {
          margin-right: 20px;
          font-size: 12px;

          color: rgba(128, 128, 128, 1);
          line-height: 16px;
        }
        .pay-btn {
          width: 68px;
          height: 30px;
          border-radius: 4px;
          border: 1px solid rgba(10, 162, 155, 1);
          font-size: 12px;
          color: rgba(10, 162, 155, 1);
          background: #fff;
          line-height: 30px;
          text-align: center;
        }
      }
    }
    .broadcast {
      -webkit-display: flex;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 20px;
      .content {
        font-size: 14px;
        color: rgba(51, 51, 51, 1);
        i {
          display: inline-block;
          width: 16px;
          height: 12px;
          margin-right: 10px;
          background: url('../../assets/img/schoolInfo/ico_cpdt@2x.png')
            no-repeat center/100% 100%;
        }
      }
      .time {
        font-size: 12px;
        color: rgba(153, 153, 153, 1);
        line-height: 16px;
        span {
          margin-left: 40px;
          color: rgba(10, 162, 155, 1);
        }
      }
    }
  }
  .model-block {
    width: 100%;
    margin-bottom: 20px;
    padding: 20px 20px 40px;
    box-sizing: border-box;
    background: #fff;
    &:last-child {
      margin-bottom: 0;
    }
    .content {
      display: flex;

      .grid-content {
        flex: auto;
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          height: 16px;
          width: auto;
        }
        .name {
          font-size: 13px;
          color: #333333;
          margin-left: 10px;
        }
      }
    }
    .content,
    .content3 {
      display: flex;
      flex-wrap: nowrap;
      padding: 40px 30px 0;
      .in-line {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .bg {
        margin-right: 20px;
        width: 394px;
        height: 222px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        border-radius: 8px;
      }
      .a-item {
        flex: 1;
        cursor: pointer;
        h4 {
          margin-bottom: 18px;
          font-size: 22px;
          font-weight: bold;
          color: rgba(51, 51, 51, 1);
        }
        .aa {
          color: red;
        }
        p {
          font-size: 14px;
          color: rgba(128, 128, 128, 1);
          line-height: 19px;
        }
      }
      .a-item2 {
        display: flex;
        align-items: center;
        cursor: pointer;
        .icon-bg {
          width: 50px;
          height: 50px;
          position: relative;
          margin-right: 20px;
          border-radius: 8px;
          background: #ff5858;
          i {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 30px;
            height: 28px;
            margin: auto;
            background: url('../../assets/img/schoolInfo/ico_lbhb@2x.png')
              no-repeat center/100% 100%;
          }
        }
        .icon-bg2 {
          i {
            width: 30px;
            height: 30px;
            background: url('../../assets/img/schoolInfo/ico_dhm@2x.png')
              no-repeat center/100% 100%;
          }
        }
        .icon-bg3 {
          i {
            width: 30px;
            height: 30px;
            background: url('../../assets/img/schoolInfo/ico_pint@2x.png')
              no-repeat center/100% 100%;
          }
        }
        .info {
          .info-title {
            font-size: 18px;
            color: rgba(51, 51, 51, 1);
            line-height: 32px;
          }
          .info-detail {
            font-size: 12px;
            color: rgba(102, 102, 102, 1);
            line-height: 16px;
          }
        }
      }
      .a-item3 {
        flex: 1;
        box-sizing: border-box;
      }
      .a-item6 {
        width: 25%;
      }
      .a-item7 {
        color: #ffffff;
        flex: 1;
        margin-right: 17px;
      }
      .bgcolor1,
      .bgcolor2,
      .bgcolor3,
      .bgcolor4,
      .bgcolor5,
      .bgcolor6 {
        padding: 20px;
        border-radius: 4px;
      }
      .bgcolor1 {
        background: #fe5b5b;
      }
      .bgcolor2 {
        background-color: #6b80fb;
      }
      .bgcolor3 {
        background-color: #31ba50;
      }
      .bgcolor4 {
        background-color: #f75fb4;
      }
      .bgcolor5 {
        background-color: #f18745;
      }
      .bgcolor6 {
        background-color: #498dec;
      }
      .a-item3,
      .a-item6,
      .a-item7 {
        .ico-wrap {
          img {
            margin-right: 14px;
          }
          display: flex;
          align-items: center;
        }
        .f-icon {
          display: inline-block;
          background: url('../../assets/img/schoolInfo/icon.png') no-repeat
            center;
          background-size: 20px 149px;
        }
        .bg-ico_8 {
          width: 16px;
          height: 20px;
          background-position: -0 -69px;
        }
        .bg-ico_1 {
          width: 16px;
          height: 20px;
          background-position: -0 -89px;
        }
        .bg-ico_2 {
          width: 20px;
          height: 15px;
          background-position: -0 -0;
        }
        .bg-ico_7 {
          width: 15px;
          height: 18px;
          background-position: -0 -15px;
        }
        .bg-ico_6 {
          width: 18px;
          height: 18px;
          background-position: -0 -33px;
        }
        .bg-ico_3 {
          width: 18px;
          height: 20px;
          background-position: -0 -109px;
        }
        .bg-ico_4 {
          width: 20px;
          height: 20px;
          background-position: -0 -129px;
        }
        .bg-ico_5 {
          width: 18px;
          height: 18px;
          background-position: -0 -51px;
        }

        span {
          font-size: 16px;
          line-height: 21px;
        }
      }
    }
    .first {
      // background-color: red;
      width: 100%;
    }

    .tip {
      border-radius: 0px 4px 4px 0;
      margin: 30px 20px 0px 0px;
      width: 100%;
      overflow: hidden;
      font-size: 12px;
      height: 30px;
      background: rgba(255, 235, 235, 1);
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border: 0px solid rgba(255, 214, 214, 1);
      box-sizing: border-box;
      color: rgba(255, 53, 53, 1);
      .zi1 {
        box-sizing: border-box;
        float: left;
        padding-top: 10px;
        text-indent: 20px;
      }
      .bt {
        float: right;
        width: 68px;
        height: 40px;
        border-radius: 0px 4px 4px 0;
        background: rgba(255, 53, 53, 1);
        font-size: 12px;
        color: white;
        padding-top: 9px;
        box-sizing: border-box;
        cursor: pointer;
        float: right;
        text-align: center;
      }
    }

    .content4 {
      padding: 20px 0 0px;
      flex-wrap: wrap;
      margin-right: 0;
      justify-content: flex-start;
      .bg {
        margin-bottom: 20px;
        margin-right: 0;
      }
    }
  }
}
@media screen and (min-width: 1900px) {
  .school-wrap .model-block .content .bg {
    width: 377px;
    height: 212px;
    margin-right: 20px;
  }
}
@media screen and (max-width: 1900px) {
  .school-wrap .model-block .content .bg {
    width: 365px;
    height: 205px;
    margin-right: 20px;
  }
}
@media screen and (max-width: 1750px) {
  .school-wrap .model-block .content .bg {
    width: 350px;
    height: 196px;
    margin-right: 20px;
    margin-right: 20px;
  }
}
@media screen and (max-width: 1700px) {
  .school-wrap .model-block .content .bg {
    width: 330px;
    height: 185px;
    margin-right: 13px;
  }
}
@media screen and (max-width: 1600px) {
  .school-wrap .model-block .content .bg {
    width: 299px;
    height: 168px;
    margin-right: 16px;
  }
}
@media screen and (max-width: 1500px) {
  .school-wrap .model-block .content .bg {
    width: 250px;
    height: 142px;
    margin-right: 18px;
  }
}
@media screen and (max-width: 1400px) {
  .school-wrap .model-block .content .bg {
    width: 250px;
    height: 142px;
    margin-right: 20px;
  }
}
@media screen and (max-width: 1360px) {
  .school-wrap .model-block .content .bg {
    width: 245px;
    height: 137px;
    margin-right: 5px;
  }
}
@media screen and (max-width: 1310px) {
  .school-wrap .model-block .content .bg {
    width: 210px;
    height: 118px;
  }
}
@media screen and (max-width: 1253px) {
  .school-wrap .model-block .content .bg {
    width: 210px;
    height: 118px;
    margin-right: 4px;
  }
}
@media screen and (max-width: 1210px) {
  .school-wrap .model-block .content .bg {
    width: 210px;
    height: 118px;
    margin-right: 4px;
  }
}
::v-deep .el-carousel__arrow {
  background-color: rgba(31, 45, 61, 0.71);
}
</style>
