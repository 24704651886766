<template>
  <fade @parentEvent="close">
    <div class="fadeConfirmChild createTypes borderBox">
      <div class="bigTitle">选择创建类型</div>
      <div @click="createMiniCourse" class="xiaoban jcsb borderBox">
        <div class="left jccc">
          <div class="title">
            <span class="text">小班课（超低延迟视频互动）</span>
            <i class="newIcon"></i>
          </div>
          <p class="content">智能排课/约课，1对1辅导、一对多私教互动</p>
        </div>
        <i class="rightIcon"></i>
      </div>
      <div @click="createPage(1)" class="duoren jcsb borderBox">
        <div class="left jccc">
          <h3 class="title">大班课（多人无上限）</h3>
          <p class="content">互动直播+高清录播，多章节随意组合</p>
        </div>
        <i class="rightIcon"></i>
      </div>
      <div class="cancelButton" @click="close">取消</div>
    </div>
  </fade>
</template>
<script>
import fade from '@/components/FadeAnimation'

export default {
  name: 'CreateCourseConfirm',

  props: {
    isShow: Boolean
  },

  components: {
    fade
  },

  methods: {
    createPage(type) {
      this.close()
      this.$emit('setIframeSrc', `/Course/createCourse?course_type=${type}`)
    },

    createMiniCourse() {
      this.createPage(4)
    },

    close() {
      this.$emit('close')
    }
  }
}
</script>
<style lang="scss" scoped>
.createTypes {
  width: 414px;
  padding: 0.6rem 0.7rem 0;
  background-color: #f5f5f5;
  box-sizing: border-box;
  .bigTitle {
    text-align: center;
    margin-bottom: 0.95rem;
    height: 0.9rem;
    font-size: 0.65rem;
    font-weight: 500;
    color: rgba(102, 102, 102, 1);
  }
  .xiaoban,
  .duoren {
    display: flex;
    align-items: center;
    height: 3.9rem;
    background: rgba(255, 255, 255, 1);
    border-radius: 0.1rem;
    padding: 0.9rem 1.05rem 0.9rem 0.75rem;
    box-sizing: border-box;
    .left {
      flex: 1;
      .title {
        height: 1.05rem;
        font-size: 0.75rem;
        font-weight: 600;
        color: rgba(51, 51, 51, 1);
        line-height: 1.05rem;
        display: flex;
        align-items: center;
        .text {
          margin-right: 0.2rem;
        }
        .newIcon {
          background: url('../assets/img/createClass/ico_new.svg') no-repeat center center;
          width: 1.2rem;
          height: 0.6rem;
        }
      }
      .content {
        height: 0.8rem;
        font-size: 0.55rem;
        font-weight: 400;
        color: rgba(155, 155, 155, 1);
        line-height: 0.8rem;
      }
    }
    .rightIcon {
      width: 1.3rem;
      height: 1.05rem;
    }
  }
  .xiaoban {
    margin-bottom: 0.7rem;
    .rightIcon {
      background: url('../assets/img/createClass/ico_xbk.svg') no-repeat center center;
    }
  }
  .duoren {
    .rightIcon {
      width: 1.3rem;
      height: 1.3rem;
      background: url('../assets/img/createClass/ico_zblb.svg') no-repeat center center;
    }
  }
  .cancelButton {
    width: 100%;
    height: 2.95rem;
    line-height: 2.95rem;
    text-align: center;
    font-size: 0.65rem;
    font-weight: 500;
    color: rgba(153, 153, 153, 1);
  }
}
</style>
